import vue from 'vue'
import auth from '@/middleware/auth.js'

export default [
    {
        path: '/',
        name: 'login',
        component: () => import(`@/apps/constelacion/pages/Login`),
        meta: {
            title: 'Bienvenidos'
        }
    },
    {
        path: '/twoauth',
        name: 'twoauth',
        component: () => import(`@/apps/constelacion/pages/TwoAuth`),
        meta: {
            title: 'Doble autentificación'
        }
    },
    {
        path: '/recovery',
        name: 'constelacion_recovery',
        component: () => import(`@/apps/constelacion/pages/Recovery`),
        meta: {
            title: 'Recuperar contraseña'
        }
    },
    {
        path: '/recovery/password/:hash',
        name: 'constelacion_recovery_password',
        component: () => import(`@/apps/constelacion/pages/Password`),
        meta: {
            title: 'Recuperar contraseña'
        }
    },
		{
        path: '/password_reset',
        name: 'constelacion_password_reset',
        component: () => import(`@/apps/constelacion/pages/ForcePassword.vue`),
        meta: {
            title: 'Cambiar contraseña'
        }
    },
	{
        path: '',
        component: () => import ('@/layouts/Constelacion'),
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import(`@/apps/constelacion/pages/Dashboard`),
                meta: {
                    title: 'Dashboard',
                    middleware: auth
                }
            },
	        {
                path: '/my-account',
                name: 'my-account',
                component: () => import(`@/apps/constelacion/pages/Account`),
                meta: {
                    title: 'Mi cuenta',
                    middleware: auth
                }
            },
            {
                path: '/my-account/tickets',
                name: 'my-account-tickets',
                component: () => import(`@/apps/constelacion/pages/Ticket`),
                meta: {
                    title: 'Tickets - Mi cuenta',
                    middleware: auth
                }
            },
            {
                path: '/my-account/viaticos',
                name: 'my-account-viaticos',
                component: () => import(`@/apps/constelacion/pages/Viatico`),
                meta: {
                    title: 'Viaticos - Mi cuenta',
                    middleware: auth
                }
            }

        ]
    }
];